import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

export default function Home() {
  return (
    <Layout>
      <SEO title="Index" />
    </Layout>
  );
}

export const query = graphql`
  query ProjectsList {
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        html
        frontmatter {
          slug
          date
          title
          published
          thumb {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
      }
    }
  }
`;
